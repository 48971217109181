const sidenav = document.getElementById("sidenav");
const expandSidenav = document.getElementById("expand-sidenav");
const sideNavLinksTexts = document.querySelectorAll(".sidenav-link-text");
const sideNavLinks = document.querySelectorAll(".nav-item");

window.onresize = () => {
  if (window.innerWidth < 992) {
    if (!sidenav?.classList.contains("overflow-hidden")) {
      sidenav?.classList.add("overflow-hidden");
    }
  } else if (window.innerWidth > 992 && expandSidenav?.getAttribute("aria-expanded") === "true") {
    expandSideNav();
  }
};

function setOverflow () {
  if (sidenav.classList.contains("overflow-hidden")) {
    sidenav.classList.remove("overflow-hidden");
  }
}

function toggleSideNav () {
  sidenav.classList.add("overflow-hidden");
  const burgerMenu = document.getElementById("side-navbar-toggler");
  if (burgerMenu.getAttribute("aria-expanded") === "true") {
    burgerMenu.setAttribute("aria-expanded", "false");
    sidenav.setAttribute("aria-expanded", "false");
    burgerMenu.classList.add("collapsed");
  } else {
    burgerMenu.setAttribute("aria-expanded", "true");
    sidenav.setAttribute("aria-expanded", "true");
    burgerMenu.classList.remove("collapsed");
  }
}

function expandSideNav () {
  sidenav.classList.add("overflow-hidden");
  if (expandSidenav.getAttribute("aria-expanded") === "false") {
    expandSidenav.setAttribute("aria-expanded", "true");
    sidenav.setAttribute("sidemenu-expanded", "true");
    for (const sideNavLinkText of sideNavLinksTexts) {
      sideNavLinkText.setAttribute("aria-expanded", "true");
    }
    for (const sideNavLink of sideNavLinks) {
      sideNavLink.setAttribute("sidemenu-expanded", "true");
    }
  } else {
    expandSidenav.setAttribute("aria-expanded", "false");
    sidenav.setAttribute("sidemenu-expanded", "false");
    for (const sideNavLinkText of sideNavLinksTexts) {
      sideNavLinkText.setAttribute("aria-expanded", "false");
    }
    sidenav.addEventListener("transitionend", () => {
      for (const sideNavLink of sideNavLinks) {
        sideNavLink.setAttribute("sidemenu-expanded", "false");
      }
    }, { once: true });
  }
}

function checkDropdownSide (clickedNavItem) {
  const sideNavDropdownMenus = document.querySelectorAll(".navbar-dropdown-menu");
  setTimeout(() => {
    for (let i = 0; i < sideNavDropdownMenus.length; i++) {
      if (sideNavDropdownMenus[i].getBoundingClientRect().bottom > window.innerHeight) {
        const moveTop = sideNavDropdownMenus[i].clientHeight - clickedNavItem.clientHeight;
        sideNavDropdownMenus[i].style.top = `-${moveTop}px`;
      }
    }
  }, 1);
}

